<template>
  <h1>Time Span Calculator</h1>
  <el-row style="margin-bottom: 20px;">
    <el-col
      :span="24"
    >
      <div
        class="grid-content"
        style="padding: 20px; background-color: #1a1a1a"
      >
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
        >
          <el-form-item
            label="Input"
            class="el-form-item-label"
          >
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              start-placeholder="Start Date"
              end-placeholder="End Date"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="success"
              @click="onClickCalculateButton"
            >
              Calculate
            </el-button>
          </el-form-item>
          <el-form-item
            label="Result Time"
            class="el-form-item-label"
          >
            <el-input
              v-model="outputResultTime"
              type="text"
              style="width: 280px"
              readonly
            />
          </el-form-item>
          <el-form-item
            label="Result Hour"
            class="el-form-item-label"
          >
            <el-input
              v-model="outputResultHour"
              type="text"
              style="width: 280px"
              readonly
            />
          </el-form-item>
          <el-form-item
            label="Result Minute"
            class="el-form-item-label"
          >
            <el-input
              v-model="outputResultMinute"
              type="text"
              style="width: 280px"
              readonly
            />
          </el-form-item>
          <el-form-item
            label="Result Second"
            class="el-form-item-label"
          >
            <el-input
              v-model="outputResultSecond"
              type="text"
              style="width: 280px"
              readonly
            />
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-bottom: 20px;">
    <el-col :span="24">
      <el-table
        :data="tableData"
        style="width: 100%;"
        :header-cell-style="{ background: '#121212'}"
        :cell-style="{ background: '#1a1a1a' }"
        :border="false"
      >
        <el-table-column
          prop="date"
          label="Date"
          width="120"
        />
        <el-table-column
          prop="name"
          label="Version"
          width="80"
        />
        <el-table-column
          prop="description"
          label="Description"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<script type="ts">
//import {defineComponent, ref} from "vue";
import {defineComponent, ref} from "vue";
import {Buffer} from "buffer";
import moment from 'moment';

export default defineComponent ({
  name: "TextCount",
  setup() {
    return {
      input: ref(''),
      outputLength: ref('0'),
      value1: ref([moment(), moment()]),
      outputResultTime: ref(''),
      outputResultHour: ref(''),
      outputResultMinute: ref(''),
      outputResultSecond: ref(''),
      tableData: [{
        date: '2021/07/09',
        name: '0.9.1',
        description: 'init'
      }]
    }
  },
  methods: {
    onClickCalculateButton() {
      let value = this.value1
      console.log({on:"onClickCalculateButton"});
      if (value) {
        const startDate = moment(value[0]);
        const endDate = moment(value[1]);
        console.log({StartDate: startDate, EndDate: endDate});
        const diff = endDate.diff(startDate);
        const duration = moment.duration(diff);
        //
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        this.outputResultTime = `${days} day(s) ${hours} hour ${minutes} minute ${seconds} second`;
        this.outputResultHour = duration.asHours();
        this.outputResultMinute = duration.asMinutes();
        this.outputResultSecond = duration.asSeconds();

      } else {
        this.outputResultTime = '';
        this.outputResultHour = '';
        this.outputResultMinute = '';
        this.outputResultSecond = '';
      }
    }
  }
})
</script>

<style scoped>

</style>